// Wait for the DOM to be ready
document.addEventListener("DOMContentLoaded", function () {
  var toTop = document.querySelector("#to-top");
// To top button
  toTop.addEventListener('click', function (event) {
    event.preventDefault();

    window.scrollTo({
      top: 0,
      behaviour: 'smooth',
    });
  });
  // Get all hash links on the page
  var hashLinks = document.querySelectorAll("a[href^='#']");
  // Loop through the hash links
  for (var i = 0; i < hashLinks.length; i++) {
    // Add a click event listener to each hash link
    hashLinks[i].addEventListener("click", function (event) {
      // Prevent the default behavior of clicking on hash links
      event.preventDefault();
      // Get the hash value from the link's href attribute
      var hash = this.getAttribute("href").substr(1);
      // Use the HTML5 history API to replace the hash with an empty string
      history.replaceState(null, null, window.location.pathname);
      // Scroll to the hash location on the page
      var target = document.getElementById(hash);
      if (target) {
        target.scrollIntoView({ behavior: "smooth" });
      }
    });
  }
});
